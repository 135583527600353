
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    Grid
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "類別",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "單位名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        // {
        //   field: "Number",
        //   title: "編號",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },

        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("unit/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("unit/query") : undefined,
        save: model ? params => model.dispatch("unit/save", params) : undefined
      },
      modalConfig: { showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "單位名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        // {
        //   field: "Number",
        //   title: "編號",
        //   span: 24,
        //   itemRender: {
        //     name: "$input",
        //     props: { placeholder: "請輸入文字", clearable: true },
        //     attrs: { type: "text" }
        //   }
        // },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    }
  }
});
